import { extendTheme } from "@chakra-ui/react";
import { glass } from "styleObjects";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const colors = {
  brand: {
    primary: "#0071bc",
    secondary: "#f7931e",
    bg: "#101017",
  },
};

const textStyles = {
  heroText: {
    fontSize: ["65px", "40px"],
    fontWeight: "bold",
  },
};

const components = {
  Button: {
    variants: {
      "app-primary": {
        bg: "#0071bc",
        fontWeight: "bold",
        color: "#FFFFFF",
      },
      "app-secondary": {
        bg: "#f7931e",
        fontWeight: "bold",
        color: "#101017",
      },

      "app-iconButton": glass
    },
    sizes: {
      xl: {
        h: "66px",
        fontSize: "26px",
        w: "179px",
        fontWeight: "bold",
      },
    },
  },
};

export const theme = extendTheme({
  colors,
  textStyles,
  components,
  breakpoints,
});
